import React, { Suspense } from 'react'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import './scss/style.scss'
import 'semantic-ui-css/semantic.min.css';
import { useRecoilState } from 'recoil';
import { authState } from './recoil';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/signup/SignUpForm'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {
  const [auth] = useRecoilState(authState);

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          {/* <Route path='/firma'> */}
          <Route exact path="/login" name="Login Page" element={
            !auth ? <Login /> :
              (auth.rol_id === 4 ? <Navigate to="/inventario/procesosInv" replace />
                : (auth.rol_id === 1 ? <Navigate to="/administrador/configuracion" replace />
                  : (auth.rol_id === 6 ? <Navigate to="/transacciones/nueva" replace />
                    : (auth.rol_id === 7 ? <Navigate to="/resta/cocina" replace />
                      : (auth.rol_id === 8 ? <Navigate to="/resta/comanda" replace />
                        : (auth.rol_id === 9 ? <Navigate to="/ventas/facturacion/nueva" replace />
                          : ((auth.rol_id === 10 || auth.rol_id === 11) ? <Navigate to="/junta/lecturas" replace />
                            : <Navigate to="/dashboard" replace />
                          )
                        )
                      )
                    )
                  )
                )
              )} />
          {/* <Route exact path="/register" name="Register Page" element={!auth ? <Register /> : <Navigate to="/dashboard" replace />} /> */}
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={auth ? <DefaultLayout /> : <Navigate to="/login" replace />} />
          {/* </Route> */}
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
